<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular">{{ tableName }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top v-if="validateSelectedRows">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-btn
                                color="primary"
                                class="mr-4 pa-0"
                                min-width="48px"
                                @click="deleteSelectedRows()"
                                :disabled="disableActionButtons || unsavedChanges"
                                :loading="deleting"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>Deletar linhas</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-btn
                                color="primary"
                                class="mr-4 pa-0"
                                min-width="48px"
                                @click="dialogGridNewItem = true"
                                :disabled="disableActionButtons || readOnly || unsavedChanges"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <span>{{ unsavedChanges ? 'Salve as alterações para criar um novo item' : 'Novo item'}}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="setRefresh()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="refresh(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="save()"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'warning' : 'primary'"
                            :disabled="disableActionButtons || readOnly || !unsavedChanges"
                            :loading="saving"
                        >
                            <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || readOnly"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApproval = true"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title v-if="actualLevel !== null">
                                <span v-if="actualLevel.id_level > 0 && actualLevel.id_status !== 4"> Aprovar nível - {{ actualLevel.id_level }} </span>
                                <span v-else-if="actualLevel.id_status === 4"> Todos os níveis aprovados </span>
                                <span v-else> Nenhum nível cadastrado </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialogApproval = false, justification = ''"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedEvent"
                                        :items="options.find(option => option.column === 'id_event').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPurpose"
                                        :items="options.find(option => option.column === 'id_purpose').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Propósito"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedCostCenter"
                                        :items="options.find(option => option.column === 'id_cost_center').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Centro de custo"
                                        persistent-hint
                                    ></v-select>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn class="my-2 mr-2" color="error" @click="approvalSelect(0)" :disabled="disableApprove || actualLevel.id_level === 1 && actualLevel.id_status !== 4">
                                    Não aprovar
                                </v-btn>
                                <v-btn class="my-2" color="success" @click="approvalSelect(1)" :disabled="disableApprove || actualLevel.id_status === 4">
                                    Aprovar
                                </v-btn>
                                <v-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="showHistory === true ? approvals : levels"
                                        :loading="loading"
                                        item-key="id_dynamic_report"
                                        sort-by="Usuário"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="warning" text @click="$router.push('/')" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="save(), $router.push('/')">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider class="mb-2"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center pt-3">
                <v-col class="d-flex justify-start">
                    <v-autocomplete
                        name="event"
                        label="Evento"
                        :items="options.find(option => option.column === 'id_event').items"
                        item-value="text"
                        return-object
                        class="pa-0 mr-4"
                        v-model="event"
                        @change="setSelectEvent()"
                        @click="backupSelectEvent = event"
                        :loading="event ? false : true"
                        style="max-width: 300px !important; z-index: 1000 !important;"
                    />

                    <v-dialog v-model="dialogSelectEvent" max-width="850px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDialogSelectEvent()" class="mr-4">Cancelar</v-btn>
                                <v-btn color="warning" text @click="selectEvent(false)" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="selectEvent(true)">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-divider vertical class="mr-4 mb-4"></v-divider>
                    
                    <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                    <div class="d-flex mt-1">
                        <v-checkbox
                            label="Mês"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1month"
                            @click="showAllMonths(1)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="260px">
                                <!-- @change="setYear1Months($event)" -->
                                <v-select
                                    v-model="year1months"
                                    :items="months"
                                    label="Escolha os meses"
                                    :item-text="item => item.text"
                                    @change="showMonth(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1months.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1month = true, showAllMonths(1)"
                                        :disabled="year1months.length === 12"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Tri"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1quarter"
                            @click="showAllQuarters(1)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year1quarters"
                                    :items="quarters"
                                    label="Escolha os trimestres"
                                    :item-text="item => item.text"
                                    @change="showQuarter(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1quarters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1quarter = true, showAllQuarters(1)"
                                        :disabled="year1quarters.length === 4"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Sem"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year1semester"
                            @click="showAllSemesters(1)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year1semesters"
                                    :items="semesters"
                                    label="Escolha os semestres"
                                    :item-text="item => item.text"
                                    @change="showSemester(1)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year1semesters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year1semester = true, showAllSemesters(1)"
                                        :disabled="year1semesters.length === 2"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Ano 1"
                            :ripple="false"
                            class="pa-0 ma-0 mr-4"
                            v-model="startingYear"
                            @click="showYear(1)"
                            :disabled="disableActionButtons"
                        />
                    </div>

                    <v-divider vertical class="mr-4 mb-4"></v-divider>

                    <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                    <div class="d-flex mt-1">
                        <v-checkbox
                            label="Mês"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2month"
                            @click="showAllMonths(2)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2months"
                                    :items="months"
                                    :item-text="item => item.text"
                                    @change="showMonth(2)"
                                    label="Escolha os meses"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2months.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2month = true, showAllMonths(2)"
                                        :disabled="year2months.length === 12"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Tri"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2quarter"
                            @click="showAllQuarters(2)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2quarters"
                                    :items="quarters"
                                    label="Escolha os trimestres"
                                    :item-text="item => item.text"
                                    @change="showQuarter(2)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2quarters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2quarter = true, showAllQuarters(2)"
                                        :disabled="year2quarters.length === 4"

                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Sem"
                            :ripple="false"
                            class="pa-0 ma-0"
                            v-model="year2semester"
                            @click="showAllSemesters(2)"
                            :disabled="disableActionButtons"
                        />

                        <v-menu
                            bottom
                            left
                            :close-on-content-click="false"
                            style="z-index: 1000 !important;"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    class="mr-4 pt-1"
                                    :disabled="disableActionButtons"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-sheet class="px-4 py-2" max-width="240px">
                                <v-select
                                    v-model="year2semesters"
                                    :items="semesters"
                                    label="Escolha os semestres"
                                    :item-text="item => item.text"
                                    @change="showSemester(2)"
                                    multiple
                                    return-object
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0">{{ item.text }}</span>

                                        <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                        <template v-if="index === 2">
                                            <span>...</span>
                                            <span class="grey--text text-caption pa-1">
                                                {{ `+ ${year2semesters.length - 2}` }}
                                            </span>
                                        </template>

                                    </template>
                                </v-select>

                                <div class="d-flex justify-end">
                                    <v-btn small color="primary" class="ma-0 mb-2"
                                        @click="year2semester = true, showAllSemesters(2)"
                                        :disabled="year2semesters.length === 2"
                                    >
                                        Selecionar todos
                                    </v-btn>
                                </div>
                            </v-sheet>
                        </v-menu>

                        <v-checkbox
                            label="Ano 2"
                            :ripple="false"
                            class="pa-0 ma-0 mr-4"
                            v-model="laterYear"
                            @click="showYear(2)"
                            :disabled="disableActionButtons"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row no-gutters class="d-flex justify-start align-center mt-2">
                <v-autocomplete
                    name="id_purpose"
                    label="Propósito"
                    :items="options.find(option => option.column === 'id_purpose').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="purpose"
                    @change="setSelectPurpose()"
                    @click="backupSelectPurpose = purpose"
                    :loading="purpose ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectPurpose" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectPurpose()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="selectPurpose(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="selectPurpose(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-autocomplete
                    name="id_cost_center"
                    label="Centro de custo"
                    :items="options.find(option => option.column === 'id_cost_center').items"
                    item-value="text"
                    return-object
                    class="pa-0 mr-4"
                    v-model="costCenter"
                    @change="setSelectCostCenter()"
                    @click="backupSelectCostCenter = costCenter"
                    :loading="costCenter ? false : true"
                    style="max-width: 300px !important; z-index: 1000 !important;"
                />

                <v-dialog v-model="dialogSelectCostCenter" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDialogSelectCostCenter()" class="mr-4">Cancelar</v-btn>
                            <v-btn color="warning" text @click="selectCostCenter(false)" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="selectCostCenter(true)">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-checkbox
                    label="Exibir contas contábeis"
                    :ripple="false"
                    class="pa-0 ma-0"
                    v-model="showChartAccount"
                />
            </v-row>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 240" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 240" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="windowSize.y - 64 - 240" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="colHeaders"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                fixedColumnsLeft="2"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="40"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="payload"
        />

        <v-dialog v-model="dialogSolver" max-width="900px"
            style="z-index: 5000 !important;"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    <span class="headline">Atingir meta</span>
                </v-card-title>
                <v-card-text>
                    <ZnapSolverDialog 
                        :solver="solverProps"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogGridNewItem"
            persistent
            max-width="900px"
            style="z-index: 5000 !important;"
        >
            <ZnapGridNewItemDialog
                :isLeasing="true"
                :dialogGridNewItem="dialogGridNewItem"
                :payload="payload"
                :dropdownOptions="dropdownOptions"
                :year1="year1"
                :year2="year2"
                @closeDialog="dialogGridNewItem = false"
                @newItemCreated="newItemCreated"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import moment from 'moment'
import CommentsMenu from '@/components/znap/CommentsMenu.vue'
import ZnapSolverDialog from '@/components/znap/ZnapSolverDialog.vue'
import ZnapGridNewItemDialog from '@/components/znap/ZnapGridNewItemDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapSolverDialog, ZnapGridNewItemDialog
    },

    props: {
        options: { type: Array, required: true },
        dropdownOptions: { type: Array, required: true },
        // tableName: { type: String, required: true },
        // tableIcon: { type: String },
        // tableDescription: { type: String }
    },

    data() {
        return {
            // Hot Table
            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterChange: (changes) => {
                    if (changes) {
                        changes.forEach(change => {
                            let row = change[0]
                            let columnName = change[1]


                            if (columnName === 'account_group_description') columnName = 'account_group'
                            if (columnName === 'asset_description') columnName = 'asset'

                            let option = this.dropdownOptions.find(d => d.column === `id_${columnName}`)
                            if (option) {
                                let value = null
                                if (change[3]) {
                                    if (columnName === 'account_group') {
                                        value = option.items.find(i => i.text === change[3]).id_account_group
                                    } else {
                                        value = option.items.find(i => i.text === change[3]).id
                                    }
                                }
    
                                this.objectData[row][`id_${columnName}`] = value

                                if (columnName === 'asset') {
                                    if (!value) {
                                        this.objectData[row]['asset_code'] = null
                                    } else {
                                        this.objectData[row]['asset_code'] = option.items.find(i => i.text === change[3]).asset_code
                                    }

                                    this.hotInstance.render()
                                }

                                if (columnName === 'asset_project') {
                                    if (!value) {
                                        this.objectData[row]['asset_project_code'] = null
                                    } else {
                                        this.objectData[row]['asset_project_code'] = option.items.find(i => i.text === change[3]).asset_project_code
                                    }

                                    this.hotInstance.render()
                                }
                            }

                            // enable save button
                            let physicalRow = this.hotInstance.toPhysicalRow(row)
                            if (!this.editedRows.includes(physicalRow)) {
                                this.editedRows.push(physicalRow)
                            }
                        })
                    }
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                afterSelection: () => {
                    this.selectedRows = this.hotInstance.getSelected()
                },
                cells: (row, col) => {
                    var cellProperties = {}

                    const stylizedRow = this.stylizedRows[row] || { index: -1 }

                    if (row === stylizedRow.index) {
                        if (stylizedRow.className) {
                            cellProperties.className = stylizedRow.className
                        }

                        if (this.readOnlyColumns.includes(col)) {
                            cellProperties.className += ` border-sides-1 font-bold`
                        }

                        if (stylizedRow.percent) {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    if (this.readOnly) {
                        cellProperties.readOnly = this.readOnly
                    }

                    return cellProperties
                }
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: {},
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            dialogSolver: false,
            solverProps: {
                columnName: null,
                column: null,
                row: null,
                rowProps: null,
                targetRow: null,
                targetValue: null,
                changeRow: null,
            },

            dialogGridNewItem: false,
            selectedRows: null,
            showChartAccount: false,

            // Options
            payload: {
                id_event: null,
                id_purpose: null,
                id_cost_center: null,
                id_account_group_version: null
            },
            event: null,
            purpose: null,
            costCenter: null,
            asset: null,
            accountGroupVersion: null,
            year1: null,
            year2: null,

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            readOnlyColumns: [],

            // Unsaved changes
            editedRows: [],
            dialogCloseGrid: false,
            dialogRefreshGrid: false,
            dialogSelectEvent: false,
            backupSelectEvent: null,
            dialogSelectPurpose: false,
            backupSelectPurpose: null,
            dialogSelectCostCenter: false,
            backupSelectCostCenter: null,
            dialogSelectAsset: false,
            backupSelectAsset: null,

            windowSize: { x: 0, y: 0 },
            loading: true,
            saving: false,
            deleting: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,
            
            // workflow
            readOnly: false,
            dialogApproval: false,
            justification: '',
            headers: [
                {
                    text: 'Semáforo', value: 'id_status'
                },
                {
                    text: 'Nível', value: 'approval_flow_level_description'
                },
                {
                    text: 'Status', value: 'status'
                },
                {
                    text: 'Usuário', value: 'name'
                },
                {
                    text: 'Data', value: 'date'
                },
                {
                    text: 'Justificativa', value: 'justification'
                }
            ],
            levels: [],
            selectedPurpose: null,
            costCenters: [],
            selectedCostCenter: null,
            selectedEvent: null,
            showHistory: false,
            approvals: null,
            userGroups: [],
            actualLevel: {
                id_level: 0,
                id_group: 0
            },
            disableApprove: true
        }
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },

    destroyed() {
        this.removePreventCloseHandler()
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        disableActionButtons() {
            let disableActionButtons = false

            for (let key in this.payload) {
                let foundOption = this.options.find(option => key === option.column)
                if (foundOption) {
                    if (this.payload[key] === null && foundOption.required) {
                        disableActionButtons = true
                    }
                }
            }

            return disableActionButtons
        },

        unsavedChanges() {
            if (this.editedRows.length > 0) {
                return true
            } else {
                return false
            }
        },

        validateSelectedRows() {
            let valid = false
            
            if (this.selectedRows) {
                if (this.selectedRows[0][1] === -1) {
                    valid = true
                }
            }

            return valid
        }
    },

    watch: {
        event (val) {
            // val.id_status === 1 ? this.readOnly = false : this.readOnly = true
            this.selectedEvent = val.id
        },

        purpose (val) {
            if (val){
                this.selectedPurpose = val.id
            }
        },

        costCenter (val) {
            if (val){
                this.selectedCostCenter = val.id
                this.levels = []
                this.approvals = []
                this.approvalListLevels()
                this.approvalHistory()
            }
        }
    },

    async beforeMount() {
        this.listUserGroups()

        try {
            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_module",
                        operator: "=",
                        value: 11
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: 9
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: null
                    }
                ]
            }

            const eventOption = this.options.find(option => option.column === 'id_event')
            const eventRes = await this.$http.post(eventOption.endpoint[0] + eventOption.endpoint[1] + '/list-options', { filter })
            if (eventRes.data.rows.length) {
                eventRes.data.rows.forEach((row, index) => {
                    eventOption.items.push(row)
                    if (index === 0) {
                        this.event = row
                        this.payload.id_event = this.event.id
                        this.payload.id_account_group_version = row.id_account_group_version
                        this.year1 = row.fiscal_year
                        this.yearMonthInputStart = row.year_month_input_start
                        this.fiscalYearStartMonth = row.fiscal_year_start_month
                        this.startingMonthIndex = row.fiscal_year_start_month - 1
                        this.year2 = this.year1 + 1
                    }
                })

                this.setDateColumns()

                filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_purpose_type",
                            operator: "=",
                            value: 2 // Investimentos
                        },
                        {
                            AndOr: "AND",
                            column: "matrix",
                            operator: "=",
                            value: 0
                        },
                        {
                            AndOr: "AND",
                            column: "id_planning_function",
                            operator: "=",
                            value: this.event.id_planning_function
                        },
                    ]
                }

                const purposeOption = this.options.find(option => option.column === 'id_purpose')
                const purposeRes = await this.$http.post(purposeOption.endpoint[0] + purposeOption.endpoint[1] + '/list-options', { filter })
                if (purposeRes.data.rows.length) {
                    purposeRes.data.rows.forEach((row, index) => {
                        purposeOption.items.push(row)
                        if (index === 0) {
                            this.purpose = row
                            this.payload.id_purpose = this.purpose.id
                        }
                    })

                    filter = {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_purpose",
                                operator: "=",
                                value: this.payload.id_purpose
                            }
                        ]
                    }

                    const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
                    const costCenterRes = await this.$http.post(costCenterOption.endpoint[0] + costCenterOption.endpoint[1] + '/list-options', { filter })
                    if (costCenterRes.data.rows.length) {
                        costCenterRes.data.rows.forEach((row, index) => {
                            costCenterOption.items.push(row)
                            if (index === 0) {
                                this.costCenter = row
                                this.payload.id_cost_center = this.costCenter.id_cost_center
                            }
                        })

                        await this.getDropdownOptions()
                        this.loadGrid(this.payload)
                    } else {
                        return this.noCostCentersFound()
                    }
                } else {
                    return this.noPurposesFound()
                }
            } else {
                return this.noEventsFound()
            }
        } catch (err) {
            this.$fnError(err)
        }
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        setAssetId(index, newVal) {
            let assetOption = this.options.find(option => option.column === 'id_asset').items
            let foundAsset = assetOption.find(asset => asset.text === newVal)
            this.objectData[index].id_asset = foundAsset.id
        },

        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges) {
                e.preventDefault()
                e.returnValue = ''
            }
        },
        
        closeDialogSolver() {
            this.dialogSolver = false
        },

        // runSolver(payload) {
        //     let result = this.solver(payload.column, payload.targetRow, payload.targetValue, payload.changeRow)
        // },

        // solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0){
        //     function ruleOf3 (initValChange, currTargetVal, initValTarget){
        //         if(initValTarget === 0 ) return 0
        //         return ( initValChange * currTargetVal ) / initValTarget
        //     }

        //     // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
        //     const table = this.hotInstance

        //     let initialValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
        //     let initialValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

        //     let calculatedChangeRow = ruleOf3( initialValueFromChangeRow , targetValue , initialValueFromTargetRow )
        //     if(!calculatedChangeRow) return { interations: 0, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }

        //     table.setDataAtCell(changeRow, column, calculatedChangeRow)

        //     // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

        //     let condition = true
        //     let interations = 0

        //     while (condition) {
        //         interations++
        //         let newValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
        //         let newValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

        //         let dif = targetValue - newValueFromTargetRow

        //         if( Math.abs(dif) <= 0.5 ){
        //             condition = false
        //             table.setDataAtCell(changeRow, column, initialValueFromChangeRow)
        //             return { interations, newValueFromChangeRow, newValueFromTargetRow }
        //         }

        //         // TODO: testar o algoritimo e ajustar os incrementos e decrementos
        //         if( dif > 0 && dif <= 1 ) newValueFromChangeRow = newValueFromChangeRow + 0.1
        //         if( dif > 1 ) newValueFromChangeRow = newValueFromChangeRow + 1
        //         if( dif < 0 && dif >= -1 ) newValueFromChangeRow = newValueFromChangeRow - 0.1
        //         if( dif < -1 ) newValueFromChangeRow = newValueFromChangeRow - 1

        //         table.setDataAtCell(changeRow, column, newValueFromChangeRow)
        //         // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

        //         if(interations > 10000) condition = false
        //     }

        //     return { interations, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }
        // },

        async getDropdownOptions() {
            for (let i = 0; i < this.dropdownOptions.length; i++) {
                if (this.dropdownOptions[i].column !== 'id_chart_account' || (this.dropdownOptions[i].column === 'id_chart_account' && this.showChartAccount)) {
                    try {
                        let payload = {
                            filter: {
                                conditions: []
                            }
                        }
    
                        if (this.dropdownOptions[i].filterable && this.dropdownOptions[i].column !== 'id_chart_account') {
                            this.dropdownOptions[i].conditions[0].value = this.payload[`${this.dropdownOptions[i].conditions[0].column}`]
                            payload.filter.conditions = this.dropdownOptions[i].conditions
                        }

                        if (this.dropdownOptions[i].filterable && this.dropdownOptions[i].column === 'id_chart_account') {
                            let accountGroupIds = this.dropdownOptions.find(d => d.column === 'id_account_group').items.map(d => {
                                return d.id_account_group
                            })

                            this.dropdownOptions[i].conditions[0].value = accountGroupIds
                            payload.filter.conditions.push(this.dropdownOptions[i].conditions[0])

                            if (this.event.id_chart_account_group_version) {
                                this.dropdownOptions[i].conditions[1].value = this.event.id_chart_account_group_version
                                payload.filter.conditions.push(this.dropdownOptions[i].conditions[1])
                            }
                        }

                        let res = await this.$http.post(this.dropdownOptions[i].endpoint[0] + `${this.dropdownOptions[i].endpoint[1]}/list-options`, payload)
                        if (res) {
                            this.dropdownOptions[i].items = res.data.rows
                        }
                    } catch (err) {
                        this.$fnError(err)
                    }
                }
            }
        },

        newItemCreated() {
            this.refresh(false)
        },

        async deleteSelectedRows() {
            this.deleting = true

            let rowsToDelete = []

            if (this.selectedRows.length === 1) {
                let firstRow = this.selectedRows[0][0]
                let lastRow = this.selectedRows[0][2]

                if (firstRow < lastRow) {
                    for (let i = lastRow; i >= firstRow; i--) {
                        rowsToDelete.push(i)
                    }
                } else if (firstRow > lastRow) {
                    for (let i = firstRow; i >= lastRow; i--) {
                        rowsToDelete.push(i)
                    }
                } else if (firstRow === lastRow) {
                    let i = firstRow
                    rowsToDelete.push(i)
                } else {
                    this.$toast.error('Erro inesperado')
                }
            } else {
                let rows = []

                this.selectedRows.forEach(r => {
                    rows.push({
                        firstRow: r[0],
                        lastRow: r[2]
                    })
                })

                rows.sort((a,b) => {
                    if (a.firstRow < b.firstRow) return 1
                    if (a.firstRow > b.firstRow) return -1
                    return 0
                })

                rows.forEach(r => {
                    if (r.firstRow < r.lastRow) {
                        for (let i = r.lastRow; i >= r.firstRow; i--) {
                            rowsToDelete.push(i)
                        }
                    } else if (r.firstRow > r.lastRow) {
                        for (let i = r.firstRow; i >= r.lastRow; i--) {
                            rowsToDelete.push(i)
                        }
                    } else if (r.firstRow === r.lastRow) {
                        let i = r.firstRow
                        rowsToDelete.push(i)
                    } else {
                        this.$toast.error('Erro inesperado')
                    }
                })
            }

            let filteredRows = rowsToDelete.filter(r => r > -1)

            let payload = {
                delete: {
                    items: []
                }
            }

            filteredRows.forEach(r => {
                payload.delete.items.push({ id_capex_planning_leasing: this.objectData[r].id_capex_planning_leasing })
            })

            try {
                const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing/mass-effect', payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.refresh(false)
                    this.deleting = false
                }
            } catch (err) {
                this.deleting = false
                this.$fnError(err)
            }
        },

        async loadGrid(payload) {
            this.loading = true
            let loadGrid = true
            this.editedRows = []
            this.selectedRows = null

            for (let key in payload) {
                let foundOption = this.options.find(option => key === option.column)
                if (payload[key] === null && foundOption.required) {
                    loadGrid = false
                }
            }

            payload['showChartAccount'] = this.showChartAccount

            if (loadGrid) {
                this.noDataMessage = null
                this.noDataBtn = { path: null, text: null }
                try {
                    const capexPlanningRes = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing' + '/list', { ...payload })
                    if (capexPlanningRes) {
                        let _colHeaders = []
                        let _columns = []
                        let resColumns = capexPlanningRes.data.columns
                        this.formulas = capexPlanningRes.data.formulas

                        capexPlanningRes.data.headers.forEach((header) => {
                            let headerIndex = parseInt(header.value.slice(5, 7), 10)
                            let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)

                            let headerText = header.text

                            if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                                if (header.value === 'value00') {
                                    let values = header.text.split(' ')
                                    headerText = `${values[0]} ${values[1] || ''}`
                                }

                                let year = null
                                if (headerIndex > 0 && headerIndex <= 12) {
                                    if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                        year = this.year1
                                    } else {
                                        year = this.year2
                                    }
                                    headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                                } else if (headerIndex >= 13 && headerIndex <= 24) {
                                    if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                        year = this.year2
                                    } else {
                                        year = parseInt(this.year2, 10) + 1
                                    }
                                    headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                                }
                            }
                            _colHeaders.push(headerText)

                            let readOnly = false
                            // Aqui modifica readOnly de acordo com id_status
                            this.readOnly ? readOnly = true: readOnly = false
                            this.event.id_status === 3 ? readOnly = true : false
                            this.event.id_status === 3 ? this.readOnly = true : false

                            if (header.editable === false) {
                                readOnly = true
                            }

                            let type = null
                            let source = null
                            let className = ''
                            let numericFormat = null
                            let itemsText = []
                            if (header.options) {
                                let found = this.dropdownOptions.find(o => o.column === header.columnId)
                                
                                itemsText.push(null)
                                found.items.forEach(item => {
                                    itemsText.push(item.text)
                                })

                                type = 'dropdown'
                                source = itemsText
                            } else if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                                type = 'numeric'
                                className += ' htRight'
                                numericFormat = {
                                    pattern: {
                                        thousandSeparated: true,
                                        negative: "parenthesis",
                                        mantissa: 2,
                                    },
                                    culture: ptBR.languageCode
                                }
                            } else {
                                type = 'text'
                                className += ' htLeft'
                            }

                            let hide = false
                            if (header.hideColumn) {
                                hide = true
                            }

                            const totalColumns = [
                                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                            ]

                            if (totalColumns.includes(header.value)) {
                                className += ' border-sides-1 font-bold'
                            }

                            _columns.push({
                                data: header.value,
                                readOnly,
                                type,
                                className,
                                numericFormat,
                                source,
                                hide,
                                columnLetter: header.columnLetter,
                            })
                        })
                        this.colHeaders = _colHeaders
                        this.columns = _columns

                        this.setHiddenColumns()
                        this.setDateReadOnlyColumns()

                        let _objectData = []

                        capexPlanningRes.data.rows.forEach((row, index) => {

                            let rowProps = {
                                index,
                                row,
                                className: '',
                                format: row.format,
                                readOnly: false,
                                percent: false,
                            }

                            let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
                                return cur[1] && typeof cur[1] === 'string' && cur[1].includes('Subtotal -') ? acc + 1 : acc + 0
                            }, 0)

                            if (subtotalsQty > 1) {
                                let counter = 0
                                for (const field in row) {
                                    if (row[field] && typeof row[field] === 'string' && row[field].includes('Subtotal -')) {
                                        if (counter > 0) {
                                            row[field] = ''
                                        }

                                        counter += 1
                                    }
                                }
                            }

                            if (row.indent) {
                                rowProps.className += ' indentedRow'
                                row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                            }

                            if (row.format.includes('%')) {
                                rowProps.percent = true
                            }

                            if (row.acquisition_date) {
                                row.acquisition_date = moment(row.acquisition_date).utc().format('DD/MM/YYYY')
                            }

                            if (row.activation_date) {
                                row.activation_date = moment(row.activation_date).utc().format('DD/MM/YYYY')
                            }

                            if (row.contract_end_date) {
                                row.contract_end_date = moment(row.contract_end_date).utc().format('DD/MM/YYYY')
                            }

                            if (row.investment_value) {
                                row.investment_value = parseFloat(row.investment_value, 10)
                            }

                            if (row.accumulated_depreciation ) {
                                row.accumulated_depreciation = parseFloat(row.accumulated_depreciation, 10)
                            }

                            if (row.period_year ) {
                                row.period_year = parseInt(row.period_year, 10)
                            }

                            if (row.discount_rate ) {
                                row.discount_rate = parseInt(row.discount_rate, 10)
                            }

                            if (row.total > 0) {
                                // rowProps.className += ' formulaRow'

                                let foundFormula = this.formulas.find(formula => formula.system_id === row.system_id)
                                let startIndex = this.formulas.indexOf(foundFormula)
                                let endIndex = this.formulas.indexOf(foundFormula) + 23

                                if (foundFormula) {
                                    for (let i = startIndex; i <= endIndex; i++) {
                                        row[this.formulas[i].column] = this.formulas[i].excelFormula
                                    }
                                }
                            }

                            if (row.css.length) {
                                rowProps.className += ` ${row.css}`
                            }

                            if (row.subtotal) {
                                rowProps.className += ` font-bold`
                            }

                            if (row.editable != 1) {
                                rowProps.readOnly = true
                            }

                            this.stylizedRows.push(rowProps)
                            
                            _objectData.push(row)
                        })
                        this.objectData = _objectData

                        if (!this.objectData.length) {
                            this.noDataMessage = 'Não há dados disponíveis'
                        }

                        this.loading = false
                    }
                } catch (err) {
                    this.loading = false
                    this.$fnError(err)
                }
            }
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month =  true
            this.year1quarter =  true
            this.year1semester =  true
            this.laterYear =  true
            this.year2month =  true
            this.year2quarter =  true
            this.year2semester =  true
            
            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            this.year2months = this.months
            this.year1quarters = this.quarters
            this.year2quarters = this.quarters
            this.year1semesters = this.semesters
            this.year2semesters = this.semesters
        },

        setHiddenColumns() {
            let _hiddenColumns = { columns: [], copyPasteEnabled: true }

            this.columns.forEach((column, index) => {
                if (column.hide) {
                    _hiddenColumns.columns.push(index)
                }
            })

            this.hiddenColumns = _hiddenColumns

            let cols = []

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth) {
                    if (i < 10) {
                        col = this.columns.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columns.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth) {
                    col = this.columns.findIndex(c => c.data === `value${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter) {
                    col = this.columns.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    col = this.columns.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            this.hiddenColumns.columns.push(...cols)
        },

        setDateReadOnlyColumns() {
            const monthInputStart = parseInt((this.yearMonthInputStart).toString().slice(4, 6))
            const difference = monthInputStart - this.fiscalYearStartMonth

            this.readOnlyColumns = []

            // verify if month index is before company fiscal year
            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    let col = null

                    if (i < 10) {
                        col = `value0${i}`
                    } else {
                        col = `value${i}`
                    }

                    let foundColumn = this.columns.find(c => c.data === col)
                    let foundColumnIndex = this.columns.findIndex(c => c.data === col)
                    if (foundColumn) {
                        foundColumn.readOnly = true
                        foundColumn.className += ' read-only-column'
                        this.readOnlyColumns.push(foundColumnIndex)
                    }
                }
            }

            const totalColumns = [
                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
            ]

            // verify if column is readOnly
            this.columns.forEach((c, i) => {
                if (totalColumns.includes(c.data) && c.readOnly){
                    this.readOnlyColumns.push(i)
                }
            })
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.year1months.length ? this.year1month = true : this.year1month = false
            this.year2months.length ? this.year2month = true : this.year2month = false
            
            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1quarters.length ? this.year1quarter = true : this.year1quarter = false
            this.year2quarters.length ? this.year2quarter = true : this.year2quarter = false
            
            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1semesters.length ? this.year1semester = true : this.year1semester = false
            this.year2semesters.length ? this.year2semester = true : this.year2semester = false
            
            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        async save() {
            this.saving = true

            let payload = { items: [] }

            for (let i = 0; i < this.objectData.length; i++) {
                let object = {}

                this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                    object[this.columns[index].data] = field
                })

                for (const row in this.payload) {
                    object[row] = this.payload[row]
                }

                if (object.acquisition_date) {
                    let day = object.acquisition_date.slice(0, 2)
                    let month = object.acquisition_date.slice(3, 5) - 1
                    let year = object.acquisition_date.slice(-4)
                    
                    object.acquisition_date = moment(new Date(year, month, day)).utc().format('YYYY-MM-DD')
                }

                if (object.activation_date) {
                    let day = object.activation_date.slice(0, 2)
                    let month = object.activation_date.slice(3, 5) - 1
                    let year = object.activation_date.slice(-4)
                    
                    object.activation_date = moment(new Date(year, month, day)).utc().format('YYYY-MM-DD')
                }

                if (object.contract_end_date) {
                    let day = object.contract_end_date.slice(0, 2)
                    let month = object.contract_end_date.slice(3, 5) - 1
                    let year = object.contract_end_date.slice(-4)
                    
                    object.contract_end_date = moment(new Date(year, month, day)).utc().format('YYYY-MM-DD')
                }

                payload.items.push(object)
            }

            try {
                const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing' + '/mass-save', { ...payload })
                if (res) {
                    this.saving = false
                    this.$toast.success(res.data.msg)
                    this.editedRows = []    
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }
        },

        setRefresh() {
            if (!this.unsavedChanges) {
                this.refresh(false)
            } else {
                this.dialogRefreshGrid = true
            }
        },

        async refresh(save) {
            this.loading = true
            this.dialogRefreshGrid = false

            if (save) {
                let payload = { items: [] }

                for (let i = 0; i < this.objectData.length; i++) {
                    let object = {}
                    this.hotInstance.getDataAtRow(i).forEach((field, index) => {
                        object[this.columns[index].data] = field
                    })

                    for (const row in this.payload) {
                        object[row] = this.payload[row]
                    }

                    payload.items.push(object)
                }

                try {
                    const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing' + '/mass-save', { ...payload })
                    if (res) {
                        this.editedRows = []
                        this.hotInstance = null
                        await this.getDropdownOptions()
                        this.loadGrid(this.payload)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                this.hotInstance = null
                await this.getDropdownOptions()
                this.loadGrid(this.payload)
            }
        },

        closeGrid() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            }
        },

        setSelectEvent() {
            if (!this.unsavedChanges) {
                this.selectEvent(false)
            } else {
                this.dialogSelectEvent = true
            }
        },

        closeDialogSelectEvent() {
            this.event = this.backupSelectEvent
            this.dialogSelectEvent = false
        },

        async selectEvent(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectEvent = false
            this.loading = true

            const purposeOption = this.options.find(option => option.column === 'id_purpose')
            purposeOption.items = []
            this.purpose = null
            this.payload.id_purpose = null

            const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
            costCenterOption.items = []
            this.costCenter = null
            this.payload.id_cost_center = null
            this.payload.id_event = this.event.id

            try {
                this.payload.id_account_group_version = this.event.id_account_group_version
                this.year1 = this.event.fiscal_year
                this.yearMonthInputStart = this.event.year_month_input_start
                this.fiscalYearStartMonth = this.event.fiscal_year_start_month
                this.startingMonthIndex = this.event.fiscal_year_start_month - 1
                this.year2 = this.year1 + 1

                this.setDateColumns()
                
                let filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_purpose_type",
                            operator: "=",
                            value: 2 // Despesas
                        },
                        {
                            AndOr: "AND",
                            column: "matrix",
                            operator: "=",
                            value: 0
                        },
                        {
                            AndOr: "AND",
                            column: "id_planning_function",
                            operator: "=",
                            value: this.event.id_planning_function
                        },
                    ]
                }

                const purposeOption = this.options.find(option => option.column === 'id_purpose')
                const purposeRes = await this.$http.post(purposeOption.endpoint[0] + purposeOption.endpoint[1] + '/list-options', { filter })
                if (purposeRes.data.rows.length) {
                    purposeRes.data.rows.forEach((row, index) => {
                        purposeOption.items.push(row)
                        if (index === 0) {
                            this.purpose = row
                            this.payload.id_purpose = this.purpose.id
                        }
                    })
                    
                    filter = {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_purpose",
                                operator: "=",
                                value: this.payload.id_purpose
                            }
                        ]
                    }

                    const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
                    const costCenterRes = await this.$http.post(costCenterOption.endpoint[0] + costCenterOption.endpoint[1] + '/list-options', { filter })
                    if (costCenterRes.data.rows.length > 0) {
                        costCenterRes.data.rows.forEach((row, index) => {
                            costCenterOption.items.push(row)
                            if (index === 0) {
                                this.costCenter = row
                                this.payload.id_cost_center = this.costCenter.id_cost_center
                            }
                        })

                        this.refresh(false)
                    } else {
                        return this.noCostCentersFound()
                    }
                } else {
                    return this.noPurposesFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        noEventsFound() {
            this.event = -1
            this.payload.id_event = null
            this.purpose = -1
            this.payload.id_purpose = null
            this.costCenter = -1
            this.payload.id_cost_center = null
            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
        },

        setSelectPurpose() {
            if (!this.unsavedChanges) {
                this.selectPurpose(false)
            } else {
                this.dialogSelectPurpose = true
            }
        },

        closeDialogSelectPurpose() {
            this.purpose = this.backupSelectPurpose
            this.dialogSelectPurpose = false
        },

        async selectPurpose(save) {
            if (save) {
                this.save()
            }
        
            this.dialogSelectPurpose = false
            this.loading = true
            this.noDataMessage = null
            this.noDataBtn.path = { path: null, text: null }
            this.options.find(option => option.column === 'id_cost_center').items = []
            this.costCenter = null
            this.payload.id_cost_center = null
            this.payload.id_purpose = this.purpose.id

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_purpose",
                        operator: "=",
                        value: this.payload.id_purpose
                    }
                ]
            }

            try {
                const costCenterOption = this.options.find(option => option.column === 'id_cost_center')
                const costCenterRes = await this.$http.post(costCenterOption.endpoint[0] + costCenterOption.endpoint[1] + '/list-options', { filter })
                if (costCenterRes.data.rows.length > 0) {
                    costCenterRes.data.rows.forEach((row, index) => {
                        costCenterOption.items.push(row)
                        if (index === 0) {
                            this.costCenter = row
                            this.payload.id_cost_center = this.costCenter.id_cost_center
                        }
                    })
    
                    this.refresh(false)
                } else {
                    return this.noCostCentersFound()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        noPurposesFound() {
            this.purpose = -1
            this.payload.id_purpose = null
            this.costCenter = -1
            this.payload.id_cost_center = null
            this.noDataMessage = 'Cadastre um próposito para iniciar o planejamento'
            this.noDataBtn.path = '/purpose'
            this.noDataBtn.text = 'Cadastrar propósito'

            this.loading = false
        },

        setSelectCostCenter() {
            if (!this.unsavedChanges) {
                this.selectCostCenter(false)
            } else {
                this.dialogSelectCostCenter = true
            }
        },

        closeDialogSelectCostCenter() {
            this.costCenter = this.backupSelectCostCenter
            this.dialogSelectCostCenter = false
        },

        selectCostCenter(save) {
            if (save) {
                this.save()
            }

            this.dialogSelectCostCenter = false
            this.loading = true
            this.payload.id_cost_center = this.costCenter.id_cost_center

            this.refresh()
        },

        noCostCentersFound() {
            this.costCenter = -1
            this.payload.id_cost_center = null
            this.noDataMessage = 'Cadastre um centro de custo para iniciar o planejamento'
            this.noDataBtn.path = '/purpose-cost-center'
            this.noDataBtn.text = 'Cadastrar centro de custo'

            this.loading = false
        },

        selectAsset(e) {
            let hideRows = []
            let showRows = []
            let plugin = this.hotInstance.getPlugin('hiddenRows')

            this.objectData.forEach((item, index) => {
                showRows.push(index)
            })

            plugin.showRows(showRows)

            if (e) {
                this.objectData.forEach((item, index) => {
                    if (item.id_asset !== e.id) {
                        hideRows.push(index)
                    }
                })

                plugin.hideRows(hideRows)
            } else {
                this.asset = -1
            }

            this.hotInstance.render()
        },

        async exportSheet(){
            this.exporting = true

            let columnsToRemove = this.setColumnsToRemove()

            let payload = {
                columnsToRemove,
                ...this.payload
            }

            try {
                const res = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        setColumnsToRemove() {
            let columnsToRemove = []

            // year 1 months
            for (let i = 1; i <= 12; i++) {
                let foundMonth = this.year1months.find(m => m.id === i)

                let index = i < 10 ? `0${i}` : i

                if (!foundMonth) {
                    columnsToRemove.push(`value${index}`)
                }
            }

            // year 2 months
            for (let i = 13; i <= 24; i++) {
                let foundMonth = this.year2months.find(m => m.id === i - 12)

                if (!foundMonth) {
                    columnsToRemove.push(`value${i}`)
                }
            }

            // year 1 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year1quarter${i}`)
                }
            }

            // year 2 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year2quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year2quarter${i}`)
                }
            }

            // year 1 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year1semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year1semester${i}`)
                }
            }

            // year 2 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year2semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year2semester${i}`)
                }
            }

            // years
            if (!this.startingYear) {
                columnsToRemove.push(`year1`)
            }

            if (!this.laterYear) {
                columnsToRemove.push(`year2`)
            }

            return columnsToRemove
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        // const massEffectRes = await this.$http.post(this.$ipCapexPlanning + 'capex-planning-leasing' + '/mass-effect', { ...uploadRes.data.id })
                        // if (massEffectRes) {
                            this.$toast.success(uploadRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh(false)
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        async approvalSelect (option) {
            const payload = {
                id_event: this.selectedEvent,
                id_purpose: this.purpose.id,
                id_cost_center: this.costCenter.id_cost_center,
                justification: this.justification
            }
            try {
                if (option === 1) {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/approve', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } else {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/disapprove', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
            this.dialogApproval = false
            this.justification = ''
            await this.approvalListLevels()
            this.approvalHistory()
            this.refresh()
        },

        async approvalListLevels () {
            let unapprovedLevels = []
            const payload = {
                id_event: this.event.id,
                id_purpose: this.purpose.id,
                id_cost_center: this.costCenter.id_cost_center
            }

            if (!payload.id_event || !payload.id_purpose || !payload.id_cost_center) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                        level.id_status === 5 || !level.id_status ? unapprovedLevels.push(level) : false
                    })
                    unapprovedLevels.length < 1 ? this.actualLevel = this.levels[this.levels.length - 1] : this.actualLevel = unapprovedLevels[0]
                    this.userGroups.forEach((group) => {
                        if (group.id_group === this.actualLevel.id_group) {
                            this.disableApprove = false
                        }
                    })
                    await this.getlevels()
                } else {
                    this.readOnly = false
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory () {
            const payload = {
                id_event: this.event.id,
                id_purpose: this.purpose.id,
                id_cost_center: this.costCenter.id_cost_center
            }

            if (!payload.id_event || !payload.id_purpose || !payload.id_cost_center) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-cost-center/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((approval) => {
                        approval.date ? approval.date = moment(approval.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async listUserGroups () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_user",
                            operator: "=",
                            value: this.getUser.id_user
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipSecurity + 'user-group/list', { ...payload })
                if (res) {
                    this.userGroups = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getlevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_approval_flow_level",
                            operator: "=",
                            value: this.actualLevel.id_approval_flow_level
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow-level/list', { ...payload })
                if (res) {
                    if (res.data.rows.length > 0) {
                        res.data.rows[0].change_values === 1 ? this.actualLevel.readOnly = false : this.actualLevel.readOnly = true
                    }
                    if (this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4){
                        this.readOnly = this.actualLevel.readOnly
                        this.readOnly === true ? this.$toast.warning('Este nível de aprovação não permite alterações') : false
                    } else {
                        this.levels.length > 0 ? this.readOnly = true : this.readOnly = false
                        if (this.readOnly) {
                            this.$toast.success('Este evento está com todos os níveis aprovados')
                        }
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        }
    },
}
</script>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
