<template>
    <v-card style="position: relative;">
        <v-card-title class="light--text"
            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
            style="position: sticky; top: 0; width: 100%; z-index: 1000;"
        >
            <span ref="formTitle" class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>

            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-btn v-on="on"
                        class="pa-0"
                        min-width="48px"
                        text dark
                        @click="closeDialog"
                    >
                        <v-icon color="light">mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Fechar</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row no-gutters v-if="!loading">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-autocomplete
                            v-model="assetClass"
                            :label="`${assetClassOption.label} *`"
                            :items="assetClassOption.items"
                            item-value="text"
                            return-object
                        />

                        <v-autocomplete
                            v-model="asset"
                            :label="`${assetOption.label} *`"
                            :items="filteredAssetItems"
                            item-value="text"
                            return-object
                            :disabled="!assetClass"
                        />

                        <v-autocomplete
                            v-model="accountGroup"
                            :label="`${accountGroupOption.label} *`"
                            :items="accountGroupOption.items"
                            item-value="text"
                            return-object
                        />

                        <v-autocomplete
                            v-model="chartAccount"
                            :label="`${chartAccountOption.label}`"
                            :items="filteredChartAccountItems"
                            item-value="text"
                            return-object
                            :disabled="!accountGroup"
                        />

                        <v-autocomplete
                            v-model="assetProject"
                            :label="`${assetProjectOption.label}`"
                            :items="assetProjectOption.items"
                            item-value="text"
                            return-object
                        />

                        <v-text-field
                            v-model="projectItemCode"
                            label="Código do subitem"
                            type="text"
                        />

                        <v-text-field
                            v-model="projectItemDescription"
                            label="Descrição do subitem"
                            type="text"
                        />

                        <v-menu ref="acquisitionDate"
                            v-model="acquisitionDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="formatDate(acquisitionDate)"
                                    label="Data de aquisição *"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <v-date-picker
                                v-model="acquisitionDate"
                                no-title
                                scrollable
                                color="primary"
                            >
                                <v-spacer></v-spacer>
                                
                                <v-btn text color="primary" @click="acquisitionDateMenu = false">
                                    Alterar
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                        <v-menu ref="activationDate"
                            v-model="activationDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="formatDate(activationDate)"
                                    label="Data de ativação *"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <v-date-picker
                                v-model="activationDate"
                                no-title
                                scrollable
                                color="primary"
                            >
                                <v-spacer></v-spacer>
                                
                                <v-btn text color="primary" @click="activationDateMenu = false">
                                    Alterar
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                        <v-menu ref="contractEndDate" v-if="isLeasing"
                            v-model="contractEndDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="formatDate(contractEndDate)"
                                    label="Data do término do contrato *"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <v-date-picker
                                v-model="contractEndDate"
                                no-title
                                scrollable
                                color="primary"
                            >
                                <v-spacer></v-spacer>
                                
                                <v-btn text color="primary" @click="contractEndDateMenu = false">
                                    Alterar
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                        <v-text-field
                            v-model.number="investmentValue"
                            label="Valor do ativo *"
                            type="number"
                        />

                        <v-text-field
                            v-model="accumulatedDepreciation"
                            label="Depreciação acumulada"
                            type="number"
                        />

                        <v-autocomplete
                            v-model="status"
                            :label="statusOption.label"
                            :items="statusOption.items"
                            item-value="text"
                            return-object
                        />
                    </v-col> 
                </v-row>
                <v-row v-else class="mt-4">
                    <v-col class="d-flex justify-center align-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            small
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
            <v-divider class="mx-4"></v-divider>
            <v-card-actions class="px-6 py-6">
                <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" text class="mr-4"
                    @click="closeDialog"
                >
                    Cancelar
                </v-btn>
                <v-btn color="primary"
                    @click="confirm"
                    :disabled="disableConfirm"
                    :loading="confirming"
                >
                    Confirmar
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
import moment from 'moment'

export default {
    name: 'ZnapFormulaDialog',

    props: {
        isLeasing: { type: Boolean, required: true },
        dialogItem: { type: Boolean, required: true },
        dropdownOptions: { type: Array, required: true },
        event: { type: Object, required: true },
        purpose: { type: Array, required: true },
        costCenter: { type: Array, required: true },
        year1: { type: Number, required: true },
        year2: { type: Number, required: true },
        duplicatingItem: { type: Boolean, required: true },
        selectedRow: { type: Object, required: false, default: null }
    },

    watch: {
        dialogItem: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.init()
                }          
            }
        },
        
        acquisitionDate (val) {
            this.activationDate = moment(val).add(30, 'days').format('YYYY-MM-DD')
        },

        // chartAccount (val) {
        //     console.log(val)
        // }
    },

    computed: {
        formTitle() {
            let formTitle = 'Novo item'

            if (this.duplicatingItem) {
                formTitle = 'Duplicar item'
                return formTitle
            }

            if (this.selectedRow) {
                formTitle = 'Editar item'
                return formTitle
            }

            return formTitle
        },

        disableConfirm() {
            let disabled = false

            if (!this.asset) disabled = true
            if (!this.assetClass) disabled = true
            if (!this.accountGroup) disabled = true
            if (!this.acquisitionDate) disabled = true
            if (!this.activationDate) disabled = true
            if (!this.investmentValue) disabled = true

            return disabled
        },

        filteredAssetItems() {
            if (!this.assetClass) {
                return
            }

            return this.assetOption.items.filter(i => i.id_asset_class === this.assetClass.id)
        },

        filteredChartAccountItems() {
            if (!this.accountGroup) {
                return
            }

            return this.chartAccountOption.items.filter(i => i.id_account_group === this.accountGroup.id_account_group)
        },

        assetClassOption() {
            return this.dropdownOptions.find(o => o.column === 'id_asset_class')
        },

        assetOption() {
            return this.dropdownOptions.find(o => o.column === 'id_asset')
        },

        assetProjectOption() {
            return this.dropdownOptions.find(o => o.column === 'id_asset_project')
        },

        chartAccountOption() {
            return this.dropdownOptions.find(o => o.column === 'id_chart_account')
        },

        accountGroupOption() {
            return this.dropdownOptions.find(o => o.column === 'id_account_group')
        },

        statusOption() {
            return this.dropdownOptions.find(o => o.column === 'id_status')
        }
    },

    data() {
        return {
            newItem: [],

            datepicker: [],
            date: '',
            selected: [],

            loading: true,
            confirming: false,

            acquisitionDateMenu: false,
            activationDateMenu: false,
            contractEndDateMenu: false,

            assetClass: null,
            accountGroup: null,
            chartAccount: null,
            assetProject: null,
            asset: null,
            projectItemCode: null,
            projectItemDescription: null,
            acquisitionDate: null,
            activationDate: null,
            investmentValue: null,
            accumulatedDepreciation: null,
            status: null,

            // TODO 
            contractEndDate: null,
        }
    },

    methods: {
        init() {
            if (this.selectedRow) {
                // console.log({...this.selectedRow})

                const idAssetClass = this.selectedRow.id_asset_class
                this.assetClass = this.assetClassOption.items.find(i => i.id === idAssetClass)

                const idAsset = this.selectedRow.id_asset
                this.asset = this.filteredAssetItems.find(i => i.id === idAsset)

                const idAccountGroup = this.selectedRow.id_account_group
                this.accountGroup = this.accountGroupOption.items.find(i => i.id_account_group === idAccountGroup)

                const idChartAccount = this.selectedRow.id_chart_account || null
                if (idChartAccount) {
                    this.chartAccount = this.chartAccountOption.items.find(i => i.id_chart_account === idChartAccount)
                }

                const idAssetProject = this.selectedRow.id_asset_project || null
                if (idAssetProject) {
                    this.assetProject = this.assetProjectOption.items.find(i => i.id === idAssetProject)
                }
                
                this.projectItemCode = this.selectedRow.project_item_code
                this.projectItemDescription = this.selectedRow.project_item_description

                this.acquisitionDate = this.selectedRow.acquisition_date ? this.parseDate(this.selectedRow.acquisition_date) : null
                this.activationDate = this.selectedRow.activation_date ? this.parseDate(this.selectedRow.activation_date) : null
                this.investmentValue = this.selectedRow.investment_value
                this.accumulatedDepreciation = this.selectedRow.accumulated_depreciation
                this.selectedRow.contract_end_date ? this.parseDate(this.selectedRow.contract_end_date) : null

                const idStatus = this.selectedRow.id_status || null
                if (idStatus) {
                    this.status = this.statusOption.items.find(i => i.id === idStatus)
                }
            }

            this.loading = false
        },

        resetState() {
            this.assetClass = null
            this.accountGroup = null
            this.chartAccount = null
            this.assetProject = null
            this.asset = null
            this.projectItemCode = null,
            this.projectItemDescription = null,
            this.acquisitionDate = null
            this.activationDate = null
            this.investmentValue = null
            this.accumulatedDepreciation = null
            this.contractEndDate = null
            this.status = null
        },

        parseDate(date) {
            let year = date.slice(6, 10)
            let month = date.slice(3, 5)
            let day = date.slice(0, 2)
            return `${year}-${month}-${day}`
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
            this.date = this.$options.filters.dateFilter(date)
        },

        clearDatepicker(item, e) {
            if (e) {
                item.val = null
            }
        },

        closeDialog() {
            this.resetState()
            this.$emit('closeDialog')
        },

        confirm() {
            this.confirming = true

            let payload = {
                items: [
                    {
                        id_event: this.event.id,
                        id_purpose: this.purpose[0].id,
                        id_cost_center: this.costCenter[0].id_cost_center,
                        id_asset_class: this.assetClass.id,
                        id_account_group: this.accountGroup.id_account_group,
                        id_chart_account: this.chartAccount ? this.chartAccount.id_chart_account : null,
                        id_asset_project: this.assetProject ? this.assetProject.id : null,
                        id_asset: this.asset.id,
                        project_item_code: this.projectItemCode,
                        project_item_description: this.projectItemDescription,
                        acquisition_date: this.acquisitionDate,
                        activation_date: this.activationDate,
                        investment_value: this.investmentValue,
                        accumulated_depreciation: this.accumulatedDepreciation,
                        contract_end_date: this.contractEndDate ? this.contractEndDate : null,
                        id_status: this.status ? this.status.id : null
                    }
                ]
            }

            let endpoint = this.isLeasing ? 'capex-planning-leasing' : 'capex-planning'

            if (this.selectedRow && !this.duplicatingItem) {
                payload.items[0]['id_capex_planning'] = this.selectedRow.id_capex_planning
                return this.editItem(payload, endpoint)
            }

            return this.createItem(payload, endpoint)
        },

        async createItem(payload, endpoint) {
            payload['saveItems'] = 1

            try {
                const res = await this.$http.post(this.$ipCapexPlanning + `${endpoint}/mass-save`, payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.closeDialog()
                    this.confirming = false
                    this.$emit('newItemCreated')
                }
            } catch (err) {
                this.$fnError(err)
                this.confirming = false
            }
        },

        async editItem(payload, endpoint) {
            payload['saveItems'] = 1
            
            try {
                const res = await this.$http.post(this.$ipCapexPlanning + `${endpoint}/mass-save`, payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.closeDialog()
                    this.confirming = false
                    this.$emit('newItemCreated')
                }
            } catch (err) {
                this.$fnError(err)
                this.confirming = false
            }
        },
    }
}
</script>

<style scoped>
</style>
